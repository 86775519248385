<template>
  <tab-container>
    <div slot="operation">
      <a-range-picker
        style="width: 380px"
        :show-time="{
          defaultValue: [
            $moment('00:00:00', 'HH:mm:ss'),
            $moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        format="YYYY-MM-DD"
        @ok="onChangeTimeRange"
        @change="initData"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    >
      <template slot="url" slot-scope="record">
        <span :class="$style.tableImg" @click="openImg(record)"
          ><img :src="record" alt="" srcset=""
        /></span>
      </template>
    </a-table>
  </tab-container>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Preview } from '@triascloud/x-components';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import { deviceTypeEnum } from '@/enum/device';
import RecordTable from '@/views/green-construction/components/mixins/record-table';
import { deviceRecord } from '@/services/device-manage/device-detail';
import { UploadService } from '@triascloud/services';
import { exchangeDirectionValue } from '@/views/green-construction/components/utils';

@Component({
  components: {
    TabContainer,
  },
})
export default class TurnoverLog extends mixins(RecordTable) {
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.turnoverTime'),
        dataIndex: 'time',
        width: 180,
        ellipsis: true,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.turnoverDirection'),
        dataIndex: 'direction',
        ellipsis: true,
        customRender: txt => (txt ? exchangeDirectionValue(txt) : ''),
      },
      {
        align: 'left',
        title: this.$t('carManagement.plateNumber'),
        width: 160,
        ellipsis: true,
        dataIndex: 'licensePlateNumber',
      },
      {
        align: 'left',
        title: this.$t('carManagement.vehicleType'),
        dataIndex: 'vehicleType',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('carManagement.vehicleNature'),
        dataIndex: 'vehicleNature',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.snapshots'),
        dataIndex: 'url',
        customRender: (text, record) =>
          record.url?.length ? (
            <x-oss-image
              basePath="/oss/iot/oss"
              ossPath={record.url[0]}
              onClick={() => this.openImg(record.url)}
              class="table-img"
            />
          ) : (
            ''
          ),
      },
    ];
  }
  async openImg(pictures) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(pictures);
    Preview.createModal(abPath);
  }
  getRecordList(queryParams, deviceGroup) {
    return deviceRecord(queryParams, deviceGroup);
  }
  deviceGroup = deviceTypeEnum.VL.deviceGroup;
}
</script>

<style lang="less" module>
.tableImg {
  cursor: pointer;
  img {
    object-fit: contain;
    width: 30px;
  }
}
</style>
